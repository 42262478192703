<template>
  <div
    class="sd-sync-tag bg-red d-flex align-items-center uppercase font-12 p-2 text-white mr-2 mb-2"
  >
    Not Connected To {{ integrationType }}
  </div>
</template>
<script>
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';

export default {
  name: 'SyncStatus',
  setup() {
    const integrationType = getBusinessSourceType();
    return {
      integrationType,
    };
  },
};
</script>
<style lang="scss" scoped>
.sd-sync-tag {
  line-height: 0.35rem;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: -0.3px;
  border-color: theme-color("red");
}
</style>
